.btn {
    transition: all 0.4s ease;
    min-width: 215px;
    min-height: 72px;
    background: linear-gradient(to  right, #662e19 0%, #eda460 50%, #d6915a 100%);
    border-radius: 10px;
    text-align: center;
    font-size: 1.375rem;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    color: $white;
    line-height: 72px;
    border: none;
    @include media('<=phone') { 
        font-size: 1rem;
        line-height: 55px;
        min-width: 150px;
        min-height: 55px;
    }
    &.small {
        border-radius: 5px; 
        min-width: 240px;
        min-height: 74px;
        @include media('<=desktop') {
            min-width: 200px;
        }
        @include media('<=phone') {
            font-size: 0.8rem;
            line-height: 50px;
            min-width: 150px;
            min-height: 50px;
        }
    }
    &:hover {
        transition: all 0.4s ease;
        background: linear-gradient(90deg, #D6915A 0%, #EDA460 48.75%, #662E19 100%);
    }
    &.cart {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-width: 350px;
        @include media('<=tablet') {
            min-width: 300px;
        }
        img {
            margin-left: 10px;
        }
    }
}