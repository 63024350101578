.header-place {
  height: 100px;
  background: $mercury;
}

#wrapper {
  overflow: hidden;
}

a {
  color: $base-link-color;
  text-decoration: none;
  transition: all 0.4s ease;
  outline: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.search-holder,
.filter,
.select {
  width: 240px;
  overflow: hidden;
  border: 2px solid $gray;
  position: relative;
  @include media("<=desktop") {
    width: 195px;
  }
  @include media("<=tablet") {
    width: 100%;
  }

  input,
  select {
    height: 50px;
    float: left;
    padding: 0 16px;
    font-size: 13px;
    width: calc(100% - 30px);
    box-sizing: border-box;
  }

  &:after {
    right: 16px;
  }
}

.search-holder {
  &:after {
    @include size(22px, 21px);
    content: "";
    background: url("./../../assets/pages/ico-search.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    right: 20px;
    cursor: pointer;
    pointer-events: none;
    transition: 0.25s all ease;
  }

  input {
    border: none;

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}

.select {
  &.filter {
    &:after {
      @include size(22px, 21px);
      content: "";
      background: url("./../../assets/pages/ico-filter.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      position: absolute;
      top: 50%;
      margin-top: -11px;
      right: 20px;
      cursor: pointer;
      pointer-events: none;
      transition: 0.25s all ease;
      border: none;
    }
  }
}

.select {
  &:after {
    content: "\25BC";
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 9px;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    pointer-events: none;
    transition: 0.25s all ease;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background-image: none;
    color: $black;
    cursor: pointer;
  }

  select::-ms-expand {
    display: none;
  }
}

.tabset > label {
  position: relative;
  display: inline-block;
  padding: 15px 0;
  border: 1px solid transparent;
  border-bottom: 0;
  cursor: pointer;
  font-weight: 600;
  margin-right: 90px;
  font-size: 1.125rem;
  color: $dovegray;
  @include media("<=phone") {
    margin-right: 40px;
  }
}

.tabset > label::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: 1px;
  background: transparent;
}

.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}

.tabset > label:hover {
  color: $black;
}

.tabset > input:checked + label {
  color: $black;
}

.tabset > label:hover::after,
.tabset > input:focus + label::after,
.tabset > input:checked + label::after {
  background: #06c;
}

.tab-panel {
  padding: 50px 0;
  border-top: 1px solid #ccc;
}

.pagination-holder {
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
}

.tab-panel {
  .text {
    max-width: 805px;
    font-size: 18px;
    line-height: 30px;
    @include media("<=phone") {
      font-size: 14px;
      line-height: 25px;
    }
    p {
      margin: 0 0 30px;
    }
  }
}

#breadcrumbs {
  margin-bottom: 47px;

  ul {
    @extend %listreset;

    li {
      display: inline;
      text-transform: uppercase;

      &:before {
        content: "\200B";
      }
    }

    a {
      color: $black;
    }

    a:after {
      content: "";
      display: inline-block;
      border-top: solid 1px $black;
      border-right: solid 1px $black;
      transform: rotate(45deg);
      height: 0.35em;
      width: 0.38em;
      margin: 0 0.4em 0.2em;
    }
  }
}

.pagination {
  @extend %clearfix;
  @extend %listreset;
  display: flex;
  align-items: center;
  li {
    display: inline-block;
    font-size: 13px;
    line-height: 35px;
    margin: 0 5px;
    color: $darkergray;
    @include media("<=phone") {
      margin: 0 3px;
      line-height: 30px;
    }
    &.active {
      a {
        color: $black;
        background: #ebebeb;
        border-color: #ebebeb;
      }
    }

    &:first-child {
      transform: rotate(180deg);
      a {
        line-height: 31px;
      }
    }

    a {
      color: $darkergray;
      display: block;
      text-align: center;
      font-size: 13px;
      line-height: 35px;
      @include size(38px, 38px);
      border-radius: 100%;
      border: 2px solid $darkergray;
      @include media("<=phone") {
        @include size(30px, 30px);
        line-height: 30px;
      }
      &:hover {
        background: #ebebeb;
      }
    }
  }
}

.container {
  max-width: 1462px;
  //padding-left: 15px;
  //padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.pager-holder {
  max-width: 898px;
  margin: 0 auto;
  padding: 60px 0;

  @include media("<=phone") {
    padding: 30px 0 0;
  }

  .pager-left {
    transform: rotate(-180deg);
    float: left;
  }

  .pager-right {
    float: right;
  }

  .pager-left,
  .pager-right {
    @include size(40px, 40px);
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
      border-radius: 2px;
    }
  }

  img {
    width: 12px;
  }
}

.pager {
  @extend %clearfix;
  @extend %listreset;
  font-size: 20px;
  line-height: 20px;
  overflow: hidden;
  text-align: center;

  @include media("<=phone") {
    font-size: 16px;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    color: $darkgray;
    margin: 0 17px;

    @include media("<=phone") {
      margin: 0 10px;
    }

    &.active {
      a {
        color: $fuelyellow;
        border-color: $fuelyellow;
      }
    }

    a {
      color: $darkgray;
      display: inline-block;
      vertical-align: top;
      padding: 0 3px;
      padding-bottom: 20px;
      border-bottom: 3px solid transparent;

      &:hover {
        color: $fuelyellow;
      }
    }
  }
}

#main {
  margin-bottom: 40px;
}

.newsletter-card {
  border-radius: 13px;
  background: $white;
  @extend %clearfix;
  margin-bottom: 64px;

  .title {
    float: left;
    width: 42%;
  }
}

.newsletter-form {
  float: right;
  width: 58%;
  border: 2px solid #e6e7ea;
  border-radius: 5px;
  padding: 10px;

  @include media("<=tablet") {
    width: 100%;
  }

  @include media("<=phone") {
    border: none;
    padding: 0;
  }

  .btn {
    @include media("<=phone") {
      float: right;
    }
  }
}

.form-control {
  border-radius: 0;
  font-size: 21px;
  height: 74px;
  padding: 2px 20px;
  width: 100%;
  border: none;
  color: black;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @include media("<=phone") {
    font-size: 13px;
    height: 60px;
  }
}

.input-group {
  width: calc(100% - 240px);
  float: left;

  @include media("<=desktop") {
    width: calc(100% - 200px);
  }

  @include media("<=phone") {
    width: 100%;
    margin-bottom: 20px;
    border: 2px solid #e6e7ea;
    border-radius: 5px;
  }
}

.newsletter-holder {
  @extend %clearfix;
}

#footer {
  .newsletter-card {
    position: relative;
    margin-top: -170px;

    &:after {
      content: "";
      background: url("./../../assets/pages/shadow.svg");
      width: 1466px;
      height: 53px;
      position: absolute;
      top: 100%;
      left: 50%;
      pointer-events: none;
      margin-left: -733px;
      transform: rotate(-180deg);
    }

    .newsletter-holder {
      padding: 85px 137px;
      background: $white;
      border-radius: 13px;
      display: flex;
      align-items: center;

      @include media("<=widescreen") {
        padding: 85px 60px;
      }

      @include media("<=tablet") {
        flex-direction: column;
      }

      @include media("<=phone") {
        padding: 40px;
      }
    }

    .title {
      color: $bluezodiac;
      font-size: 2.5rem;

      @include media("<=desktop") {
        font-size: 1.9rem;
      }

      @include media("<=tablet") {
        display: block;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
      }

      @include media("<=phone") {
        font-size: 1.3rem;
      }
    }
  }

  .box {
    @include size(1920px, 342px);
    background-image: url("./../../assets/pages/shape.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
  }

  .logo {
    width: 10%;
    order: 2;
    padding: 0 2px;

    @include media("<=tablet") {
      width: 153px;
      order: 1;
    }

    a {
      display: block;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}

.footer-nav-holder {
  @extend %clearfix;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 59px;
  display: flex;
  align-items: center;

  @include media("<=tablet") {
    flex-direction: column;
    justify-content: center;
  }

  @include media("<=phone") {
    padding-bottom: 43px;
  }

  .col {
    width: 40%;
    float: left;

    @include media("<=tablet") {
      width: 100%;
    }

    &:first-child {
      width: 60%;

      @include media("<=tablet") {
        width: 100%;
      }
    }
  }
}

.footer-nav {
  ul {
    @extend %clearfix;
    @extend %listreset;
    font-size: 1.3125rem;
    display: flex;

    @include media("<=tablet") {
      justify-content: center;
      margin-bottom: 40px;
    }

    @include media("<=phone") {
      display: block;
      font-size: 1rem;
      text-align: center;
      padding-bottom: 10px;
    }

    li {
      margin-right: 106px;
      margin-bottom: 5px;

      @include media("<=desktop") {
        margin-right: 60px;
      }

      @include media("<=tablet") {
        margin: 0 20px 10px;
      }

      @include media("<=phone") {
        margin: 0 20px 20px;
      }

      a {
        color: $bluezodiac;

        &:hover {
          color: $fuelyellow;
        }
      }
    }
  }
}

.social-nav {
  ul {
    @extend %clearfix;
    @extend %listreset;
    font-size: 1.3125rem;
    display: flex;
    justify-content: flex-end;

    @include media("<=tablet") {
      justify-content: center;
    }

    @include media("<=phone") {
      font-size: 1rem;

      img {
        width: 25px;
      }
    }

    li {
      margin-left: 53px;
      margin-bottom: 5px;

      @include media("<=tablet") {
        margin: 0 20px 10px;
      }

      a {
        display: block;
        position: relative;
        transition: all 0.3s ease;
        top: 0;

        &:hover {
          top: -3px;
        }
      }
    }
  }
}

.support-links-holder {
  @extend %clearfix;
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  padding: 38px 0;

  @include media("<=tablet") {
    flex-direction: column;
    justify-content: center;
  }
}

.copyright-holder {
  order: 1;
  width: 44.5%;

  @include media("<=tablet") {
    width: 100%;
    order: 3;
    text-align: center;
  }
}

.copyright {
  font-size: 1.125rem;

  @include media("<=tablet") {
    font-size: 1rem;
  }

  @include media("<=phone") {
    font-size: 0.8rem;
  }
}

.colors-area {
  padding: 32px 0;

  input[type="radio"] {
    display: none;

    &:checked + label {
      span {
        transform: scale(1.25);
      }

      @each $name, $value in $radioColors {
        .#{$name} {
          border: 2px solid darken($value, 25%);
        }
      }
    }
  }

  label {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 100%;

    &:hover {
      span {
        transform: scale(1.25);
      }
    }

    span {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      transition: transform 0.2s ease-in-out;

      @each $name, $value in $radioColors {
        &.#{$name} {
          background: $value;
        }
      }
    }
  }
}

.product-help {
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-bottom: 40px;
}

.support-links {
  width: 44.5%;
  order: 3;
  @extend %clearfix;
  @extend %listreset;
  font-size: 1.125rem;
  justify-content: flex-end;
  display: flex;

  @include media("<=tablet") {
    width: 100%;
    order: 2;
    justify-content: center;
    padding: 60px 0 30px;
  }

  @include media("<=phone") {
    font-size: 1rem;
  }

  li {
    margin-left: 53px;
    margin-bottom: 5px;

    @include media("<=tablet") {
      margin: 0 20px 10px;
    }

    @include media("<=phone") {
      margin: 0 15px 10px;
    }

    a {
      color: $bluezodiac;

      &:hover {
        color: $fuelyellow;
      }
    }
  }
}
