// Colors
@import url("https://use.typekit.net/ask1bef.css");
$primary: #222E36;
$secondary: #F3903A;
$thistle-green: #CAC6AA;
$wildsand: #F5F5F5;
$mako: #474F55;
$spanish-green: #859987;
$tango: #EDA460;
$trout: #505A60;
$makonext: #3D4347;
$outerspace: #2D383F;
$opa: #AAC3BF;    
$abbey: #474C50;
$mercury: #E5E5E5;
$mineralgreen: #3D635A;
$plantation: #25433C;
$jaffa: #F79143;
$gray: #ccc;
$darkgray: #4A4A4A;
$darkergray: #8C8C8C;
$black: #000000;
$dovegray:#666666;
$white: #fff;
$bluezodiac: #0A142F;
$fuelyellow: #E9A426;
$alto: #CFCFCF;
$light-blue: $tango;

$radioColors: (
    abbey: #4B4D4F,
    merino: #F2E9DC,
    mischka: #EAE8EB,
);

$base-font-sans-serif: alverata-informal, serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $primary !default;
$base-background-color: $white !default;
$font-size-base: 14px !default; 
$line-height-base: 1.2 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $light-blue;

// Buttons 
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;


$headings-font-family: inherit !default;
$headings-color: inherit !default;


$animation-speed: 0.3s;
