.banner-area {
    overflow: hidden;
    height: 650px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    text-align: center;
    color: $white;
    font-size: 1.125rem;
    line-height: 1.7rem;
    @include media('<=phone') {
        font-size: 1rem;
        line-height: 1.3rem;
        height: 500px;
    }
    .container {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .text {
        margin: 0 auto;
        max-width: 945px;
        p {
            margin-bottom: 35px;
        }
    }
    h1 {
        font-size: 3rem;
        margin-bottom: 35px;
        @include media('<=desktop') {
            line-height: 3rem;
        }
        @include media('<=phone') {
            font-size: 2rem;
            line-height: 2rem;
        }
    }
}

.banner-img-area {
    margin-bottom: 100px;
    @include media('<=phone') {
        margin-bottom: 40px;
    }
    img {    
        width: 100%;
        height: auto;
        display: block;
    }
}

.post-detail-holder {
    h1 {
        font-size: 3rem;
        color: $black;
        margin: 0 0 17px;
        @include media('<=phone') {
            font-size: 2rem;
            line-height: 2rem;
        }
    }
    .head {
        max-width: 1084px;
        text-align: center;
        margin: 0 auto 26px;
    }
    .text {
        max-width: 805px;
        margin: 0 auto;
        font-size: 1.125rem;
        line-height: 1.6rem;
        color: $black;
        @include media('<=phone') {
            font-size: 1rem;
            line-height: 1.4rem;
        }
        p {
            margin:0 0 30px;
        }
    }
    .by {
        margin: 0 6px 10px;
        color: rgba(28, 28, 28, 0.5);
        font-size: 1rem;
        @include media('<=phone') {
            display: block;
        }
    }
    .time {
        position: relative;
        padding-left: 20px;
        margin: 0 6px 10px;
        color: rgba(28, 28, 28, 0.5);
        font-size: 1rem;
        @include media('<=phone') {
            display: block;
            &:after {
                display: none;
            }
        }
        &:after {
            content: "";
            @include size(4.65px, 3px);
            @include position(absolute, 8px, auto, auto, 0);
            background: rgba(28, 28, 28, 0.5);
            border-radius: 50%;
        }
    }
}

.about-author {
    @include media('<=phone') {
        padding: 50px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .title {
        font-size: 1.25rem;
        font-weight: normal;
        text-transform: uppercase;
        color: rgba(28, 28, 28, 0.5);
        margin-bottom: 15px;
        display: block;
    }
    .holder {
        overflow: hidden;
    }
    .img-area {
        @include size(160px, 160px);
        float: left;
        margin-right: 37px;
        display: inline-block;
        vertical-align: top;
        @include media('<=phone') {
            float: none;
            margin-right: 0;
            margin-bottom: 30px;
        }
        img {
            @include size(100%, 100%);
            object-fit: cover;
            display: block;
            border-radius: 100%;
        }
    }
    .text-block {
        font-size: 1rem;
        overflow: hidden;
        max-width: 200px;
        max-width: 450px;
        @include media('<=phone') {
            width: 100%;
        }
        span {
            font-size: 1rem;
            color: rgba(28, 28, 28, 0.5);
            margin-bottom: 13px;
            display: block;
            a {
                color: rgba(28, 28, 28, 0.5);
                &:hover {
                    color: $black;
                }
            }
        }
        p {
            margin: 0 0 10px;
            font-size: 1rem;
            line-height: 1.2rem;
        }
    }
    h2 {
        font-size: 2.0625rem;
        margin: 0 0 5px;
        @include media('<=phone') {
            font-size: 1.8rem;
            line-height: 1.9rem;
        }
    }
}

.tag-list {
    overflow: hidden;
    text-align: center;
    padding: 13px 0 0;
    color: rgba(28, 28, 28, 0.5);
    span {
        font-size: 1rem;
    }
}

.blog-categories {
    overflow: hidden;
    padding: 100px 0 0;
    @include media('<=phone') {
        padding: 40px 0 0;
    }
    .posts {
        overflow: hidden;
        margin: 0;
        display: flex;
        flex-flow: wrap row;
        .post {
            overflow: hidden;
            width: 50%;
            padding: 0 15px;
            margin-bottom: 20px;
            @include media('<=phone') {
                width: 100%;
            }
        }
        .img-holder {
            overflow: hidden;
            margin-bottom: 37px;
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
    .meta {
        overflow: hidden;
        .tag {
            font-size: 1.25rem;
            font-weight: normal;
            text-transform: uppercase;
            color: rgba(28, 28, 28, 0.5);
            margin-bottom: 0;
        }
        h2 {
            margin:0 0 20px;
            font-size: 2.0625rem;
            @include media('<=tablet') {
                font-size: 2rem;
            }
            @include media('<=phone') {
                font-size: 1.7rem;
            }
            a {
                color: $black;
                &:hover {
                    color: $jaffa;
                }
            }
        }
        p {
            font-size: 1rem;
            line-height: 1.4rem;
            margin: 0 0 10px;
        }
        .text {
            overflow: hidden;
            font-size: 1rem;
            margin-bottom: 4px;
            color: rgba(28, 28, 28, 0.5);
            .by {
                margin-right: 50px;
                margin-bottom: 10px; 
                float: left;
                @include media('<=phone') {
                    float: none;
                    display: block;
                    margin-right: 0;
                }
            }
            .time {
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;
                float: left;
                @include media('<=phone') {
                    float: none;
                    display: block;
                    margin-left: 0;
                    padding-left: 0;
                    &:after {
                        display: none;
                    }
                }
                &:after {
                    content: "";
                    @include size(4.65px, 3px);
                    @include position(absolute, 8px, auto, auto, 0);
                    background: rgba(28, 28, 28, 0.5);
                    border-radius: 50%;
                }
            }
        }
    }
    .cols {
        @extend %clearfix;
        max-width: 1639px;
        margin: 0 auto 64px;
        .col {
            width: 33.3%;
            display: inline-block;
            vertical-align: top;
            margin-left: -4px;
            padding: 0 14px;
            position: relative;
            @include media('<=phone') {
                width: 100%;
                margin-left: 0;
                padding: 0 20px 30px;
            }
            a {
                display: block;
                border-radius: 10px;
                height: 100%;
                position: relative;
            }
            img {
                width: 100%;
                height: auto;
                display: block;
                border-radius: 10px;
                overflow: hidden;
            }
            .caption {
                @include position(absolute,0,0,0,0);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 3rem;
                text-transform: uppercase;
                color: $white;
                font-weight: bold;
                @include media('<=desktop') {
                    font-size: 2rem;
                }
            }
        }
    }
}