#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width:100%;
  object-fit: cover;
  filter: brightness(75%);
  z-index: -1;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    content: "";
  }
}
